import React from 'react'
import Header from '../components/Header'
import styles from './HomePage.module.css'
import Footer from '../components/Footer'
import screenHomeAluno from '../assets/screens/celular360.png'
import PerguntasFrequentes from '../components/PerguntasFrequentes'

const HomePage = () => {
  return (
    <div className={styles.all}>
      <Header />
      <div className={styles.HeroSec}>
        <div className={styles.HeroRight}>
          <div className={styles.HeroRight_content}>
            <h2>Venha ser Educação Alternativa 360, feita para quem sabe o que é ser uma pequena escola.</h2>
            <p>Assuma o controle definitivo da gestão da sua escola com quem realmente entende o que é ser pequeno.</p>
            <br />
            <button>Experimente na sua escola</button>
          </div>
        </div>
        <div className={styles.HeroLeft}>
          <img src={screenHomeAluno} />
        </div>
      </div>
      <PerguntasFrequentes />
      <Footer />
    </div>
  )
}

export default HomePage