import React from 'react'
import img from '../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png'
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
    const navigate = useNavigate();
    const items = [
        {
            title: "Conheça o software",
            links: [
                {
                    name: "...",
                    link: ""
                },
                {
                    name: "...",
                    link: ""
                },
            ]
        },
        {
            title: "Sobre nós",
            links: [
                {
                    name: "...",
                    link: ""
                },
                {
                    name: "...",
                    link: ""
                },
            ]
        },
        {
            title: "Conteúdos",
            links: [
                {
                    name: "sdfsdf...",
                    link: "sdfsd"
                },
                {
                    name: "sdfsdf",
                    link: "dsfdsf"
                },
            ]
        },
        {
            title: "Atendimento",
            links: [
                {
                    name: "sdgdsf",
                    link: "sdfdsf"
                },
                {
                    name: "sdfsdf",
                    link: "sdfdsf"
                },
            ]
        },
    ]

    return (
        <div className={styles.footer}>
            <div className={styles.footer_top}>
                <img src={img} onClick={() => navigate()} />
                <div className={styles.social_media}>
                    <p><FaInstagram /></p>
                    <p><FaYoutube /></p>
                    <p><FaLinkedin /></p>
                </div>
            </div>
            <div className={styles.footer_sec}>
                <div  className={styles.footer_sec_grid}>
                    {
                        items.map((item) => (
                            <div>
                                <h3>{item.title}</h3>
                                {item.links.map((i) => (
                                    <p onClick={() => navigate(i.link)}>{i.name}</p>
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.footer_bottom}>
                <div>
                    <p>© 2024 Educação alternativa 360 | Todos os direitos reservados | Desenvolvido por Alternativo Colaço Soluções Tecnológicas</p>
                    <p onClick={() => navigate('/politica-de-privacidade')} style={{ cursor: "pointer" }}>Políticas de Privacidade e Segurança da Informação</p>
                </div>
            </div>
        </div>
    )
}

export default Footer