import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import img from '../assets/Logos/Cópia de Cópia de Logotipo escola municipal minimalista azul e laranja.png';
import { Link, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaSchool } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { MdFamilyRestroom } from "react-icons/md";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [acessOpen, setAcessOpen] = useState(false);
    const [openItems, setOpenItems] = useState({});
    const navigate = useNavigate();

    const items = [
        {
            name: "SuperApp",
            to: "",
            item: [
                { name: "Subitem 1", to: "/farofa" },
                { name: "Subitem 2", to: "/" },
                { name: "Subitem 3", to: "/" },
            ]
        },
        {
            name: "Conteudo",
            to: "",
            item: [
                { name: "Subitem 1", to: "/" },
                { name: "Subitem 2", to: "/" },
                { name: "Subitem 3", to: "/" },
            ]
        }
    ];

    const AcessItems = [
        {
            icon: <FaSchool />,
            name: "Escola",
            to: "https://escola.educacaoalternativa360.com.br/"
        },
        {
            icon: <PiStudentFill />,
            name: "Aluno",
            to: "https://web.educacaoalternativa360.com.br/"
        },
        {
            icon: <MdFamilyRestroom />,
            name: "Responsável",
            to: "https://web.educacaoalternativa360.com.br/"
        },
    ]

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleAcess = () => {
        setAcessOpen(!acessOpen);
    };

    const toggleItem = (index) => {
        setOpenItems((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    // Function to handle window resize
    const handleResize = () => {
        if (window.innerWidth <= 997) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.header}>
            <div style={menuOpen ? { height: '100vh', background: "white" } : null} className={styles.container}>
                <div style={menuOpen ? { height: '100px' } : null} className={styles.navbar}>
                    <div className={styles.w_nav}>
                        <img onClick={() => navigate('/')} src={img} alt="Logo" />
                    </div>
                    <div className={styles.w_nav_menu}>
                        <div className={styles.itemsMenu}>
                            {items.map((item, index) => (
                                <div className={styles.itemsMenu_div} key={index}>
                                    <p className={styles.itemsMenu_div_link} onClick={() => toggleItem(index)} style={{ cursor: 'pointer' }}>
                                        {openItems[index] === true ? <>{item.name}<IoIosArrowUp className={styles.arrowIcon} /></> : <>{item.name}<IoIosArrowDown className={styles.arrowIcon} /></>}
                                    </p>
                                    <div>
                                        {openItems[index] && item.item.map((i, subIndex) => (
                                            <div key={subIndex}>
                                                <Link to={i.to}>{i.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <div className={styles.itemsMenu_div}>
                                <p className={styles.itemsMenu_div_link} onClick={() => navigate('/quem-somos')} style={{ cursor: 'pointer' }}>
                                    Quem somos
                                </p>
                            </div>
                        </div>
                        <div className={styles.button}>
                            <button onClick={toggleAcess}>Acessar</button>
                        </div>
                        <div className={styles.prevMenu}>
                            <div className={styles.hamburgerMenu} onClick={toggleMenu}>
                                <p><GiHamburgerMenu /></p>
                            </div>
                        </div>

                    </div>
                </div>
                {menuOpen && (
                    <div className={styles.mobileMenu}>
                        <div className={styles.mobileMenu_grid}>
                            {items.map((item, index) => (
                                <>
                                    <div key={index}>
                                        <p  className={styles.itemsMenu_div_link} onClick={() => toggleItem(index)} style={{ cursor: 'pointer' }}>
                                        {openItems[index] === true ? <>{item.name}<IoIosArrowUp className={styles.arrowIcon} /></> : <>{item.name}<IoIosArrowDown className={styles.arrowIcon} /></>}
                                        </p>
                                        {openItems[index] && item.item.map((i, subIndex) => (
                                            <div key={subIndex}>
                                                <Link to={i.to}>{i.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ))}
                            <div className={styles.itemsMenu_div}>
                                <p className={styles.itemsMenu_div_link} onClick={() => navigate('/quem-somos')} style={{ cursor: 'pointer' }}>
                                    Quem somos
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {acessOpen && (
                    <div className={styles.AcessAll} onClick={() => setAcessOpen(false)}>
                        <div className={styles.ContentAll}>
                            <div>
                                <h2>Selecione seu perfil de acesso</h2>
                            </div>
                            <div style={{ height: "20px", width: "100%" }}></div>
                            <div className={styles.ListAcess}>
                                {
                                    AcessItems.map((item, index) => (
                                        <div className={styles.AccessItem} key={index}>
                                            <div className={styles.icon}>
                                                {item.icon}
                                            </div>
                                            <div className={styles.name}>
                                                <h3>{item.name}</h3>
                                            </div>
                                            <div>
                                                <Link className={styles.button} to={item.to}>Acessar</Link>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
