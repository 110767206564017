import React, { useState } from 'react';
import styles from './PerguntasFrequentes.module.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const PerguntasFrequentes = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    const items = [
        {
            ask: "Como as famílias têm acesso a plataforma Educação Alternativa 360?",
            answer: "Para acessar o 360, clique no botão 'Acessar' no cabeçalho da página. Em seguida, insira seu e-mail e senha, e selecione sua escola para entrar."
        },
        {
            ask: "Como posso ter o acesso à plataforma?",
            answer: "Resposta para a pergunta 2."
        },
        // Adicione mais perguntas e respostas aqui
    ];

    return (
        <div className={styles.oftenAsk}>
            <div className={styles.oftenAsk_top}>
                <h2>Perguntas frequentes</h2>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className={styles.oftenAsk_bottom}>
                <div className={styles.ListOftenAsk}>
                    {items.map((item, index) => (
                        <div className={styles.ItemOftenAsk} key={index}>
                            <div 
                                className={`${styles.ask} ${openIndex === index ? styles.active : ''}`}
                                onClick={() => handleToggle(index)}
                            >
                                <div className={styles.top}>{item.ask}</div>
                                <div className={styles.bottom}>
                                    {openIndex === index ? <IoIosArrowUp/> : <IoIosArrowDown style={{color:"#ec3237"}}  />}
                                </div>
                            </div>
                            {openIndex === index && (
                                <div className={styles.answer}>
                                    {item.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PerguntasFrequentes;
